.btn {
  outline: none;
  border: none;
  padding: 15px 20px;
  line-height: 1;
  font-size: 16px;
  border-radius: 5px;
  font-weight: 400;
  font-family: 'Poppins-regular';

}

.btn[color-variant='primary'] {
  background-color: var(--primary-color);
  color: var(--text-color-black);
}

.btn[color-variant='secondary'] {
  color: var(--white-color);
  background-color: var(--secondary-color);
}
.btn[color-variant='outlined-secondary'] {
  background-color: var(--white-color);
  color: var(--secondary-color);
  border: 1px solid var(--secondary-color);
}

.btn:disabled{
  cursor: no-drop;
}