.main {
  max-width: 96% !important;
  padding-block: 40px;
  height: 100vh;
  overflow-y: auto;
}

.quillInput {
  height: calc(100vh - 200px);
  background-color: var(--white-color);
  border: 1px solid var(--7070-clr) !important;
  box-shadow: unset !important;
  border-radius: 10px;
}
.row {
  row-gap: 10px;
}
.mt30 {
  margin-top: 30px;
}
