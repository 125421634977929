.page{
    max-width: 96% !important;
    padding-block: 40px;
    height: 100vh;
    overflow-y: auto;
}

.rowGap{
    row-gap: 20px;
}

.quillClass{
    background-color: var(--white-color);
    border: 1px solid var(--7070-clr);
    box-shadow: unset !important;
    border-radius: 10px;
}
.addMoreBox{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--white-color);
    border: 1px solid var(--7070-clr);
    border-radius: 10px;
    height: 218px;
}
.addMoreBox p{
    margin-bottom: 0px !important;
}
.label{
    color: var(--dashboard-color);
    text-transform: capitalize;
    font-family: "Poppins-regular";
    font-size: 16px;
    margin-bottom: 8px
}