.OtpInput_style {
  width: 100% !important;
  height: 60px;

  margin-left: 20px;
  color: var(--black-color) !important;
  border: 1px solid #bababa;
  border-radius: 8px;
  font-size: 24px;
  font-weight: bold;
  outline: none;
}

.mainContainer {
  height: 100vh;
  background-color: var(--white-color);
}

.col1 {
  /* background-color: var(--clr-primary); */
  background-image: linear-gradient(to bottom, #e65d9040, #2364aa),
    url("../../assets/images/visitor-img1.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  padding-inline: 50px;
  padding-block: 30px;
  border-radius: 0px 40px 40px 0px;
}

.logo {
  width: 200px;
  height: auto;
}
.logo img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.col2 {
  display: flex;
  padding-left: 150px;
  height: 100vh;
  align-items: center;
}

.desc {
  padding-right: 100px;
}
.desc p {
  color: var(--white-color);
}

.language {
  max-width: 300px;
  margin-inline-start: auto;
}
.formWrapper {
  width: clamp(450px, 70%, 600px);
}
.form {
  background-color: var(--white-color);
  width: 100%;
  border-radius: 1rem;
}
.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.content {
  margin-block: 2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.inputWrapper {
  display: grid;
  gap: 2rem;
  margin-bottom: 1rem;
}
.btn {
  width: 100% !important;
  margin: 30px 0px;
}
.backBtn{
  margin-bottom: 20px;
}

@media screen and (max-width: 1200px) {
  .col2 {
    padding-left: 70px;
  }
}

@media screen and (max-width: 992px) {
  .col2 {
    padding: 0px;
  }
  .formWrapper {
    margin-inline: auto;
    width: clamp(300px, 80%, 500px);
  }
  .mainContainer :global(.row) {
    margin: 0px;
  }
}
