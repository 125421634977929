.options_div {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.row {
  align-items: end;
  
}


@media screen and (max-width:1300px) {
  .row{
    flex-direction: column;
    align-items: unset;
  }
  .row > div:nth-child(2){
    justify-content: flex-end;
    display: flex;
    width: 100%;
  }
  
}