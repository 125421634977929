.tag-wrapper {
}
.tag-wrapper .input-tags-container {
  flex: 1;
  position: relative;
}
.tags-list {
  padding-top: 1rem;
  display: flex;
  gap: 1rem;
  max-height: 200px;
  overflow-y: auto;
  flex-wrap: wrap;
}
.header-title {
  display: flex;
  margin-bottom: 8px;
  align-items: center;
  justify-content: space-between;
}
.header-title[data-label="false"] {
  justify-content: flex-end;
}
.header-title button {
  padding: 6px 25px;
  font-size: 14px;
}
.header-title span {
  color: var(--text-color);
  text-transform: capitalize;
  font-family: "Poppins-regular";
  font-size: 16px;
  margin-bottom: 8px;
}
.tag-item {
  background: #002a3a;
  padding: 0.71rem;
  border-radius: 12px;
  display: flex;
  height: 40px;
  align-items: center;
  gap: 0.8rem;
  font-size: 16px;
  color: #fff;
  border: 1px solid var(--border-color);
}
.tag-item p {
  margin: 0;
  padding: 0;
  font-size: inherit !important;
  color: #fff;
}
.action-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
}

.iotaButton {
  position: absolute !important;
  right: 10px !important;
  top: 8px !important;
}

.iotaButton svg {
  fill: var(--text-color);
}
