.main {
  padding-block: 40px;
  max-width: 96% !important;
}
.pageHeader h3 {
  margin-bottom: 0px;
}
.profileSetting__wrapper {
}

.profileSetting__wrapper h1 {
  font-size: var(--fs-p-header-big) !important;
}

.profileSetting__wrapper h3 {
  margin-top: 15px;
}

.boxShadow__wrapper {
  box-shadow: 0px 0px 10px #00000029;
  border-radius: 10px;
  margin-top: 2rem;
  min-height: calc(100vh - 170px);
  position: relative;
  /* overflow: auto; */
  background-color: var(--white-color);
  padding: 25px 32px;
}

.input__wrapper {
  margin-top: 1rem;
}

.input__wrapper p {
  /* background-color: blue; */
  font-size: 16px !important;
}

.profilePic {
  height: 150px;
  width: 150px;
  border-radius: 50%;
}

.saveBtn {
  margin-top: 20px;
}
.formRow {
  row-gap: 16px;
}
.label {
  color: var(--text-color);
  text-transform: capitalize;
  font-family: "Poppins-regular";
  font-size: 16px;
  margin-bottom: 8px;
}
.quillInput {
  background-color: var(--white-color);
  border: 1px solid var(--7070-clr);
  box-shadow: unset !important;
  border-radius: 10px 
}

@media only screen and (max-width: 1024px) {
  .profileSetting__wrapper h3 {
    margin-top: 15px;
    font-size: 17px;
  }
}

@media only screen and (max-width: 1000px) {
  .boxShadow__wrapper {
    height: 1000px;
  }
}

@media screen and (max-width: 500px) {
  .profileSetting__wrapper {
    padding-block: 1rem;
    padding-inline: 1.5rem;
  }

  .boxShadow__wrapper {
    margin: 1.5rem 0;
  }
}

@media screen and (max-width: 375px) {
  .profileSetting__wrapper {
    padding-inline: 1rem;
  }

  .boxShadow__wrapper {
    margin: 1rem 0;
  }
}
