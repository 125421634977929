.mainContainer {
  height: 100%;
  width: 100%;
  background: var(--secondary-color);
  box-shadow: 0px 4px 20px 10px rgb(0 0 0 / 6%);
  overflow-y: scroll;
  padding-right: 10px;
  padding-left: 20px;
  position: relative;
}

.mainContainer::before {
  content: " ";
  position: absolute;
  bottom: 0%;
  left: 40%;
  clip-path: circle(48.7% at 33% 71%);
  background-color: var(--sidebar-circle-clr);
  z-index: 0;
  width: 100%;
  height: 230px;
  transform: translate(-50%, 0%);
}
.mainContainer::after {
  content: " ";
  position: absolute;
  bottom: 20%;
  right: 7%;
  background-color: var(--sidebar-circle-clr);
  z-index: 2;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.logoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: 50px;
  /* margin-bottom: 50px; */
}

.logoContainer img {
  width: auto;
  cursor: pointer;
  height: 50px;
  object-fit: contain;
  flex-shrink: 0;
}

.listItemContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-block: 10px;
  padding-left: 20px;
  margin-block: 8px;
  cursor: pointer;
  position: relative;
  user-select: none;
}

.marginZero {
  margin-block: 0px !important;
}

.listItemContainer span {
  font-family: "Poppins-medium";
  font-size: 16px;
  color: var(--white-color);
  margin-left: 18px;
}

.listItemContainer svg,
.innerItemContainer svg {
  color: var(--white-color);
}

::-webkit-scrollbar {
  display: none;
}

.innerItemContainer {
  padding-block: 10px;
  padding-left: 50px;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.innerItemContainer span {
  font-family: "Poppins-medium" !important;
  font-size: 14px;
  color: var(--white-color);
  margin-left: 18px;
}

.dropDownIcon {
  position: absolute;
  right: 15px;
  top: 22%;
  fill: var(--white-color);
}

.subActive span {
  color: var(--secondary-color);
  /* text-decoration: underline; */
}

.subActive .dropDownIcon {
  fill: var(--secondary-color);
}

.subActive {
  margin-bottom: 10px !important;
}

.active,
.subActive {
  background: var(--white-color);
  border-radius: 10px;
}

.active {
  background-color: var(--white-color) !important;
}

.active span {
  color: var(--secondary-color) !important;
}

.active svg,
.subActive svg {
  color: var(--secondary-color) !important;
}
.active svg,
.active .reactSvg path,
.subActive .reactSvg path {
  fill: var(--secondary-color);
}

.btn {
  padding: 15px 60px !important;
  margin: 7px 10px !important;
}
