.main {
  /* padding-block: 20px; */
  max-width: 96% !important;
  padding-block: 40px;
}


.tableMain {
  /* height: calc(100vh - 300px); */
}

.row {
  row-gap: 20px;
}

.row h6 {
  font-family: 'Poppins-semiBold';
}

.viewDetailBtn {
  padding-block: 14px;
  /* width: 140px; */
  color: var(--text-color-black);
  /* width: 180px; */
}

.spBtw {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.paginationDiv {
  display: flex;
  justify-content: flex-end;
}

.actions_btn {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: center;
}


.noData{
  /* min-height: calc(100vh - 300px); */
}