.GiHamburgerMenu {
  font-size: 30px;
  color: var(--secondary-color);
}
.logoutBtnDiv{
  display: flex;
  justify-content: flex-end;
  padding-top: 40px;

}
.logoutBtnDiv button{
  background-color: transparent;
  padding: 0px;
}
.logoutBtnDiv button svg{
  margin-right: 10px;
}

.sidebarDiv {
  width: 350px;
}
.contentDiv{
  background-color: var(--page-bg-clr);
  position: relative;

}
.mobileHeader{
  position: sticky;
  top: 0;
  width: 100%;
  background-color: var(--white-color);
  padding: 20px 0px;
}

.desktopContentDiv {
  width: calc(100% - 350px);
  background-color: var(--page-bg-clr);
}

@media screen and (max-width: 1440px) {
  .sidebarDiv {
    width: 270px;
  }

  .desktopContentDiv {
    width: calc(100% - 270px);
  }
}

@media screen and (max-width: 1220px) {
  .sidebarDiv {
    width: 260px;
  }

  .desktopContentDiv {
    width: calc(100% - 260px);
  }
}

@media screen and (max-width: 1100px) {
  .sidebarDiv {
    width: 250px;
  }

  .desktopContentDiv {
    width: calc(100% - 250px);
  }
}