.inputDiv {
  margin-top: 20px;
}

.inputDiv label {
  font-size: 14px;
  font-weight: 400 !important;
  color: #000000;
  margin-bottom: 5px;
  font-family: "Poppins-regular";
}
