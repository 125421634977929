
:root {
  --secondary-color: #002a3a;
  --secondary-light-color: #667f88;
  --dashboard-color: #000000;

  --text-dark-gray: #808080;
  --text-color-black: #000000;
  --placeholder-color: #c9c9c9;
  --label-color: #9b9b9b;
  --disabled-input-color: #fafafa;
  --disabled-label-color: #ababab;
  --error-color: red;

  --primary-color: #dab63c;

  --sidebar-text-color: var(--white-color);
  --text-color: #4a4a4a;
  --white-color: #ffffff;
  --page-bg-clr: #f6f7f9;
  --sidebar-circle-clr: #ffffff66;
  --7070-clr: #707070;
  --heading-clr: #082f3a;
}

.c-p{
  cursor: pointer;
}
/* Bebas-Neue */

@font-face {
  font-family: "Poppins-bold";
  src: url("../fonts/Poppins/Poppins-Bold.ttf");
}

@font-face {
  font-family: "Poppins-semiBold";
  src: url("../fonts/Poppins/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: "Poppins-medium";
  src: url("../fonts/Poppins/Poppins-Medium.ttf");
}

@font-face {
  font-family: "Poppins-regular";
  src: url("../fonts/Poppins/Poppins-Regular.ttf");
}

@font-face {
  font-family: "Poppins-italic";
  src: url("../fonts/Poppins/Poppins-Italic.ttf");
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

/*---------------------------------------------*/

a {
  font-family: "Poppins-regular";
  font-size: 14px;
  line-height: 1.7;
  color: #666666;
  margin: 0px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
}
.t-t-c{
  text-transform: capitalize;
}
a:focus {
  outline: none !important;
}

a:hover {
  text-decoration: none;
  color: var(--primary-color);
}

body p {
  font-size: 17px;
  font-family: "Poppins-regular";
}

.customPara {
  font-size: 16px;
  font-family: "Poppins-regular";
}

body h1 {
  font-size: 50px;
  font-family: "Poppins-bold";
}

body h2 {
  font-size: 40px;
  font-family: "Poppins-bold";
}

body h3 {
  font-size: 35px;
  font-family: "Poppins-bold";
}

body h4 {
  font-size: 30px;
  font-family: "Poppins-bold";
}

body h5 {
  font-size: 24px;
  font-family: "Poppins-bold";
}

body h6 {
  font-size: 20px;
  font-family: "Poppins-bold";
}
.dashboardContainer {
  max-width: 96% !important;
}
.dashboardPageMain {
  height: 100vh;
  overflow-y: auto;
}

/*---------------------------------------------*/

h1,
h2,
h3,
h4,
h5,
h6,
p,
button,
a,
svg {
  margin: 0px;
}

body p.large {
  font-size: 24px;
  font-family: "Poppins-semiBold";
}

body p.reg {
  font-size: 22px !important;
  font-family: "Poppins-bold" !important;
}

ul,
li {
  margin: 0px;
  list-style-type: none;
}

/*---------------------------------------------*/

input {
  outline: none;
  border: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}



textarea {
  outline: none;
  border: none;
}

.footer_text {
  font-size: 15px !important;
  font-family: "Poppins-regular" !important;
}

.main-btn {
  background-color: var(--main-color);
  color: var(--white-color);
  border-radius: 50px;
  outline: none;
  border: none;
}

.min_h6 {
  font-size: 20px !important;
  font-family: "Poppins-regular" !important;
}

.hero_text {
  font-size: 29px !important;
  font-family: "Poppins-bold" !important;
}

.status-active {
  background-color: #d7f8d7;
  border: 1px solid #7f7f7f;
  border-radius: 10px;
  padding: 6px 10px;
  color: green;
  font-size: 14px;
  font-family: "Poppins-medium";
  min-width: 145px;
  display: inline-block;
  text-align: center;
}

.status-pending {
  background-color: #ffa50021;
  border: 1px solid #ffa204fc;
  border-radius: 10px;
  padding: 6px 10px;
  color: #ffa204fc;
  font-size: 14px;
  font-family: "Poppins-medium";
  min-width: 145px;
  display: inline-block;
  text-align: center;
}

.status-completed {
  background-color: #f0efef;
  border: 1px solid rgb(112, 112, 112);
  border-radius: 10px;
  padding: 6px 10px;
  color: rgb(112, 112, 112);
  font-size: 14px;
  font-family: "Poppins-medium";
  min-width: 145px;
  display: inline-block;
  text-align: center;
}

.status-deactive {
  background-color: #ffe6e6;
  border: 1px solid #f04d4d;
  border-radius: 10px;
  padding: 6px 10px;
  color: red;
  font-size: 14px;
  font-family: "Poppins-medium";
  min-width: 145px;
  display: inline-block;
  text-align: center;
}
.custom_dropmain_contianer {
  margin: 0px 20px;
}
.custom_drop {
  height: 45px;
}
.custom_drop__placeholder {
  color: #fff !important;
}
.custom_dropreactSelect {
  height: 100%;
}
.custom_drop__indicator svg {
  color: #fff !important;
}
.custom_drop__indicator {
  padding: 0 !important;
}
.custom_drop__control {
  height: 100%;
  background-color: #002a3a !important;
}
.status-modify-request {
  background-color: #d9d3f9;
  border: 1px solid #4c2df5;
  border-radius: 10px;
  padding: 6px 10px;
  color: #4c2df5;
  font-size: 14px;
  font-family: "Poppins-medium";
  min-width: 145px;
  display: inline-block;
  text-align: center;
}

.mvh-100 {
  min-height: 100vh !important;
}

.text2Line {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  font-family: "Poppins-medium";
  font-size: 14px;
  color: var(--dashboard-text-color);
  text-transform: capitalize;
}

.text1Line {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;

  font-family: "Poppins-medium";
  font-size: 14px;
  color: var(--dashboard-text-color);
  text-transform: capitalize;
  margin-bottom: 0px;
}

#composition-button {
  flex-shrink: 0;
  display: flex;
  align-items: center;
}

.mvh-100 {
  min-height: 100vh !important;
}

.tooltipClass {
  width: max-content;
  display: inline-block;
}

/* Media Quories */

@media screen and (max-width: 1600px) {
  .customPara {
    font-size: 15px;
  }
}

@media screen and (max-width: 1440px) {
  body h1 {
    font-size: 47px;
  }

  body h2 {
    font-size: 37px;
  }

  body h3 {
    font-size: 33px;
  }

  body h4 {
    font-size: 27px;
  }
  body h5 {
    font-size: 22px;
  }
  body h6 {
    font-size: 19px;
  }

  .customPara {
    font-size: 15px;
  }
}


@media screen and (max-width: 1220px) {
  body h1 {
    font-size: 45px;
  }

  body h2 {
    font-size: 35px;
  }

  body h3 {
    font-size: 30px;
  }

  body h4 {
    font-size: 25px;
  }
  body h5 {
    font-size: 20px;
  }
  body h6 {
    font-size: 18px;
  }

}

@media screen and (max-width: 991px) {
  body h1 {
    font-size: 42px;
  }

  body h2 {
    font-size: 32px;
  }

  body h3 {
    font-size: 28px;
  }

  body h4 {
    font-size: 23px;
  }
  body h5 {
    font-size: 19px;
  }
  body h6 {
    font-size: 18px;
  }
  body p {
    font-size: 16px;
  }

  .customPara {
    font-size: 13px;
  }

  body p.reg,
  button {
    font-size: 12px;
  }
}

@media screen and (max-width: 768px) {
  body h1 {
    font-size: 40px;
  }

  body h2 {
    font-size: 30px;
  }

  body h3 {
    font-size: 26px;
  }

  body h4 {
    font-size: 21px;
  }
  body h5 {
    font-size: 18px;
  }
  body h6 {
    font-size: 17px;
  }
}

@media screen and (max-width: 600px) {
  body h1 {
    font-size: 38px;
  }

  body h2 {
    font-size: 28px;
  }

  body h3 {
    font-size: 24px;
  }

  body h4 {
    font-size: 20px;
  }
}

@media screen and (max-width: 480px) {
  body h1 {
    font-size: 35px;
  }

  body h2 {
    font-size: 25px;
  }

  body h3 {
    font-size: 22px;
  }

  body h4 {
    font-size: 19px;
  }

  body p {
    font-size: 15px;
  }

  .customPara {
    font-size: 12px;
  }
}

@media screen and (max-width: 375px) {
  body h2 {
    font-size: 30px;
  }
}
