.heroMain {
  min-height: 300px !important;
  position: relative;

}
.heroMain::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: ' ';
  background: transparent linear-gradient(90deg, #00293a 0%, #54707b1a 100%);
  z-index: 0;
}
.heroContainer {
  height: 100% !important;
  z-index: 2;
}
.heroSec {
  height: 100% !important;
  display: flex;
  align-items: center;
}
.heroSec h2 {
  color: var(--white-clr);
}
.heroSec h2 span {
  color: var(--secondary-clr);
}

.section1 {
  padding-top: 51px;
  min-height: 100vh;

}
.section1 .row {
  row-gap: 40px;
}
.section1 .btns {
  display: flex;
  justify-content: center;
  column-gap: 20px;
}

.section1 .productInfo {
}
.section1 .productInfo h4 {
  color: var(--primary-clr);
  margin-bottom: 10px !important;
}
.section1 .productInfo ul {
  padding-left: 0px;
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  margin-bottom: 0px;
}
.section1 .productInfo ul:not(.areaOrIndustryList) li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--5454-clr);
  font-family: SofiaProRegular;
  font-size: 16px;
}
.section1 .productInfo ul li span:last-child {
  min-width: 100px;
}
.section1 .productInfo .desc {
  color: var(--primary-clr);
  margin-top: 50px;
  margin-bottom: 20px !important;
  font-family: SofiaProMedium;
}

.section1 .productInfo .advantages {
  margin-bottom: 50px !important;
  font-family: SofiaProMedium;
  color: var(--primary-clr);
}
.section1 .productInfo .areaOrIndustryList {
}
.section1 .productInfo .areaOrIndustryList li {
  font-size: 18px;
  font-family: SofiaProMedium;
  color: var(--primary-clr);
}
.section1 .carousel {
  height: max-content;
}
.section1 .productImgDiv {
  height: 328px;
  width: 100%;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 17px;
  overflow: hidden;
  cursor: pointer;
}
.section1 .productImgDiv img {
  height: 100%;
  width: 100%;
}
.section1 .productImgFullViewDiv {
  height: 432px;
  width: 100%;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 17px;
  padding: 30px 60px;
  margin-top: 20px;
}
.section1 .productImgFullViewDiv img {
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 991px) {
  .section1 .productImgDiv {
    height: 280px;
  }
  .section1 .productImgFullViewDiv {
    height: 380px;
    padding: 20px 40px;
  }
  .section1 {
    margin-top: 41px;
    margin-bottom: 70px;
  }
  .section1 .row {
    row-gap: 30px;
  }

  .section1 .productInfo .desc {
    margin-top: 40px;
    margin-bottom: 20px !important;
  }

  .section1 .productInfo .advantages {
    margin-bottom: 40px !important;
  }
}
@media screen and (max-width: 767px) {
  .section1 .productImgDiv {
    height: 250px;
  }
  .section1 .productImgFullViewDiv {
    height: 350px;
    padding: 20px 40px;
  }
  .section1 {
    margin-top: 31px;
    margin-bottom: 60px;
  }
  .section1 .row {
    row-gap: 20px;
  }
  .section1 .productInfo .desc {
    margin-top: 30px;
    margin-bottom: 10px !important;
  }

  .section1 .productInfo .advantages {
    margin-bottom: 30px !important;
  }
}

@media screen and (max-width: 375px) {
  .btns {
    column-gap: 10px !important;
  }

}
