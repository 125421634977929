.UpdateBtn {
  color: var(--white-color);
  width: 100%;
  border-radius: 46px;
  padding-block: 20px;
}
.row {
  row-gap: 20px;
}

.row label {
  display: block;
  font-family: "Poppins-semiBold";
  margin-bottom: 4px;
}

.row p {
    font-size: 16px;
    
}
