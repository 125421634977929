.page {
  max-width: 96% !important;
  padding-block: 40px;
  height: 100vh;
  overflow-y: auto;
}

.rowGap {
  row-gap: 20px;
}

.quillClass {
  background-color: var(--white-color);
  border: 1px solid var(--7070-clr);
  box-shadow: unset !important;
  border-radius: 10px;
}
.addMoreBox {
  display: flex;
  justify-content: flex-end;
}

.label {
  color: var(--dashboard-color);
  text-transform: capitalize;
  font-family: "Poppins-regular";
  font-size: 16px;
  margin-bottom: 8px;
}

.addMoreBtn {
  /* background-color: var(--dashboard-color); */
  /* color: var(--white-color); */
  margin-bottom: 20px;
}

.inputWithBtn {
  display: flex;
  flex: 3 1;
  align-items: flex-end;
  column-gap: 10px;
  margin-bottom: 20px;
}
.inputWithBtn .input {
  width: calc(100% - 60px);
}
