.UpdateBtn {
  color: var(--white-color);
  width: 100%;
  border-radius: 46px;
  padding-block: 20px;
}
.row {
  row-gap: 20px;
}

.row label {
  display: block;
  font-family: "Poppins-semiBold";
  margin-bottom: 4px;
}

.row p {
    font-size: 16px;
    margin-bottom: 0px;
    
}
.desc{
  width: 100%;
}

.desc img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
}
.modalClass{
  height: 80vh;
  overflow-y: auto;
}