.quillInput {
  border-radius: 10px;
}

.label {
  color: var(--dashboard-color);
  text-transform: capitalize;
  font-family: "Poppins-regular";
  font-size: 16px;
  margin-bottom: 8px;
}

.quill {
}

.quillInput > div > div:nth-child(1) {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  border: none;
  border-bottom: 1px solid var(--text-color-black);
}

.quillInput > div > div:nth-child(2) {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  height: calc(100% - 42px);
  min-height: 173px;
  overflow-y: scroll;
  border: none;
}
