.btnsDiv {
  display: flex;
  justify-content: center;
  column-gap: 10px;
}
.row {
  row-gap: 20px;
}

.quillInput {
  border: 1px solid var(--7070-clr);
  border-radius: 10px;
}
.modalClass{
    max-height: 70vh;
    overflow-y: auto;
}